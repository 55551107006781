<template>
  <div>
    <Card>
      <template #title>
        {{ $t("video.upload.title") }}
      </template>
      <template #subtitle>
        {{ $t("video.upload.description") }}
      </template>
      <template #content>
        <div class="p-fluid">
          <div class="p-field">
            <label for="upload-name">{{ $t("video.upload.field_name") }}</label>
            <InputText
              id="upload-name"
              v-model="name"
              :class="{ 'p-invalid': errors['name'] }"
              :disabled="isSubmitted"
            />
            <small v-show="errors['name']" class="p-error">{{
              $t("video.upload.field_name_error")
            }}</small>
          </div>
          <div class="p-field">
            <label for="upload-keywords">{{
              $t("video.upload.field_keywords")
            }}</label>
            <Chips
              id="upload-keywords"
              v-model="keyword"
              :add-on-blur="true"
              separator=","
              :disabled="isSubmitted"
            />
          </div>
          <div class="p-field">
            <label for="upload-description">{{
              $t("video.upload.field_description")
            }}</label>
            <InputText
              id="upload-description"
              v-model="description"
              :disabled="isSubmitted"
            />
          </div>
        </div>
        <FileUpload
          name="file"
          :url="uploadUrl"
          :file-limit="1"
          :multiple="false"
          accept=".avi,.mp4,.mpg,.mkv,.mov,.asf,.webm,.ogg,.flv"
          mode="advanced"
          :show-cancel-button="false"
          :show-upload-button="readyUpload"
          :invalid-file-size-message="translation.maxSize"
          :choose-label="translation.choose"
          :upload-label="translation.upload"
          :disabled="isSubmitted"
          @before-upload="onBeforeUpload"
          @before-send="onBeforeSend"
          @error="onError"
          @upload="onUploaded"
        >
          <template #empty>
            <p>{{ $t("video.upload.msg_upload") }}</p>
          </template>
        </FileUpload>
      </template>
    </Card>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Card from "primevue/card";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import qs from "qs";
import { ulid } from "ulid";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import Process from "../../models/Process";
import { assertEmpty } from "../../service/assert";
import { GET_ACCESS_TOKEN } from "../../store/modules/auth/auth.getters.type";
import { CREATE_PROCESS } from "../../store/modules/process/process.actions.type";
import { GET_VIDEO_UPLOAD_URL } from "../../store/modules/process/process.getters.type";

export default {
  name: "StepVideoUpload",
  components: {
    Card,
    FileUpload,
    InputText,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();

    const readyUpload = computed(() => validateForm());
    const name = ref("");
    const keyword = ref();
    const description = ref("");
    const isSubmitted = ref(false);
    const pendingUploaded = ref(true);
    const errors = ref([]);
    const processId = ulid();
    const accessToken = store.getters[GET_ACCESS_TOKEN];
    const uploadUrl = ref(store.getters[GET_VIDEO_UPLOAD_URL]);
    const translation = {
      maxSize: t("video.upload.field_size_error"),
      choose: t("video.upload.button_choose"),
      upload: t("video.upload.button_upload"),
    };

    function onBeforeUpload(data) {
      isSubmitted.value = true;
      data.formData.set("id", processId);
      data.formData.set("name", name.value);
      data.formData.set("keywords", qs.stringify(keyword.value ?? []));
      data.formData.set("description", description.value);
    }

    async function onBeforeSend(request) {
      request.xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    }

    function validateForm() {
      errors.value = [];
      if (assertEmpty(name.value)) {
        errors.value["name"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    function onError() {
      isSubmitted.value = false;
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: t("video.upload.toast_error"),
        life: TOAST_LIFE,
      });
    }

    async function onUploaded() {
      await store.dispatch(
        CREATE_PROCESS,
        Process.create(
          processId,
          name.value,
          keyword.value ?? [],
          description.value
        )
      );
      await router.push({
        name: "verify-video",
        params: { processId: processId },
      });
      await toast.add({
        severity: "success",
        summary: t("common.success"),
        detail: t("video.upload.toast_success"),
        life: TOAST_LIFE,
      });
    }

    return {
      name,
      keyword,
      description,
      pendingUploaded,
      isSubmitted,
      errors,
      uploadUrl,
      translation,
      readyUpload,
      onError,
      onUploaded,
      onBeforeUpload,
      onBeforeSend,
    };
  },
};
</script>
